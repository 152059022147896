/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link, useLocation } from "react-router-dom";

import { RichiesteCampioni } from "./RichiesteCampioni";
import { RichiesteMateriale } from "./RichiesteMateriale";
import { RichiesteServizi } from "./RichiesteServizi";
import { isAgente, roles } from "../../config/config";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const categorieRichieste = {
  campioni: 0,
  materiale: 1, 
  servizi: 2,
}
const nomiCategorieRichiestePlural = {
  0: "Campioni",
  1: "Materiale pubblicitario",
  2: "Servizi",
}
const hashCategorie = Object.fromEntries(
  Object.entries(nomiCategorieRichiestePlural).map((el) => [
    el[0],
    "#/" + el[1].replace(" ", "-").toLowerCase(),
  ])
);

export const Richieste = () => {
  const classes = useStyles();

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const role = parseInt(user.idRole);

  const userCategorieRichieste = (role === roles.admin || role === roles.developer || isAgente(role))
    ? Object.values(categorieRichieste)
    : role === roles.contabile
      ? Object.values(categorieRichieste).filter((v) => v === categorieRichieste.servizi)
      : role === roles.magazziniere
        ? Object.values(categorieRichieste).filter((v) => v !== categorieRichieste.servizi)
        : [-1]
      

  const [tab, setTab] = useState(-1);
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      let newTab = Object.keys(hashCategorie).find((key) => hashCategorie[key] === hash);
      if (newTab !== undefined 
          && userCategorieRichieste.includes(parseInt(newTab))) {
        setTab(parseInt(newTab));
      }
    } else {
      setTab(userCategorieRichieste[0]);
    }
  }, [hash]);

  return (
    <>
      <Card className="card-custom gutter-b">
        <Tabs
          value={tab}
          onChange={(_, t) => setTab(t)}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
        >
          {userCategorieRichieste.map(function (v,k) {
              return (
                <Tab
                  key={k}
                  label={nomiCategorieRichiestePlural[v]}
                  value={v}
                  to={hashCategorie[v]}
                  component={Link}
                />
              );
            })}
        </Tabs>
        </Card>
      {tab === categorieRichieste.campioni
        ? <RichiesteCampioni />
        : null}
      {tab === categorieRichieste.materiale
        ? <RichiesteMateriale />
        : null}
      {tab === categorieRichieste.servizi
        ? <RichiesteServizi />
        : null}
    </>
  );
};
