/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
/*eslint-disable-next-line*/
import { shallowEqual, useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    FilteringState,
    IntegratedFiltering,
    DataTypeProvider,
    RowDetailState,
} from "@devexpress/dx-react-grid";
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableFilterRow,
    TableRowDetail,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBoxOpen,
    faSquareCheck
} from "@fortawesome/free-solid-svg-icons";
/*eslint-disable-next-line*/
import { Card, Tooltip, OverlayTrigger, Button, Form } from "react-bootstrap";

import { roles, icons, statusRichiestaServizio } from "../../config/config";
//import * as apiCampioni from "../../api/campioni";
import * as apiServizi from "../../api/servizi";

// Useful sub-components
const TableComponent = ({ ...restProps }) => (
    <Table.Table
        {...restProps}
        className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
);

const getTableRow = (clickCallback) => { 
    return ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => clickCallback(restProps.tableRow.rowId)}
            style={{
                cursor: 'pointer',
            }}
        />
    );
};

const FilterCell = (props) => {
    return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
    column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const DateFormattedColumn = (props) => (
    <DataTypeProvider
        formatterComponent={({ value }) =>
            value.format("DD MMMM YYYY, HH:mm")
        }
        {...props}
    />
);

const makeActionBtn = ({ name, disabled, tooltip, callback, variant, icon, value }) => {
    const btn = (
        <Button
            variant={variant}
            className={`mx-3 my-2`}
            onClick={!disabled ? () => callback(value) : null}
            disabled={disabled}
            title={name}
        >
            {typeof icon == "string" ? (
                <span className={`svg-icon svg-icon-md svg-icon-white`}>
                    {" "}
                    <SVG src={toAbsoluteUrl(icon)} />
                </span>
            ) : (
                <FontAwesomeIcon icon={icon} className={`svg-icon-white`} style={{ marginRight: "0.5rem" }} />
            )}
            <span className='text-white'>{name}</span>
            {/* {name} */}
        </Button>);
    if (!tooltip) return btn;
    return <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>{btn}</OverlayTrigger>
};

const StatoColumn = ({ value }) => {
    /*eslint-disable-next-line*/
    if (value == statusRichiestaServizio.inAttesa)
        return (
            // in attesa
            <div className="pl-3">
                <Chip
                    color="info"
                    variant="outlined"
                    label="In attesa"
                    icon={
                        <FontAwesomeIcon
                            icon={faBoxOpen}
                            className="svg-icon-info svg-icon-md"
                        />
                    }
                    style={{ borderColor: "var(--info)" }}
                />
            </div>
        );
    if (value === statusRichiestaServizio.approvato)
        return (
            // consegnato
            <div className="pl-3">
                <Chip
                    color=""
                    variant="outlined"
                    label="Approvato"
                    icon={
                        <FontAwesomeIcon
                            icon={faSquareCheck}
                            className="svg-icon-primary svg-icon-md"
                        />
                    }
                    style={{ borderColor: "var(--primary)" }}
                />
            </div>
        );
    /*eslint-disable-next-line*/
    if (value == statusRichiestaServizio.annullato)
        return (
            // cancellato
            <div className="pl-3">
                <Chip
                    color="danger"
                    variant="outlined"
                    label="Rifiutato"
                    icon={
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                            {" "}
                            <SVG src={toAbsoluteUrl(icons.close)} />
                        </span>
                    }
                    style={{ borderColor: "var(--danger)" }}
                />
            </div>
        );
    return (<div className="pl-3"></div>);
};

export const StatoFormattedColumn = (props) => (
    <DataTypeProvider formatterComponent={StatoColumn} {...props} />
);


const CostoWithFallbackFormattedColumn = (props) => (
    <DataTypeProvider formatterComponent={({ value, row }) => {
        if (value !== undefined) {
            return `${value}€`;
        }
        if (row['stimaCosto'] !== undefined) {
            return `(${row['stimaCosto']}€)`;
        }
        return '-';
    }}
        {...props}
    />
);

const RichiestaRowDetail = ({
    isRowLoading,
    costoIsEditing,
    costoEditedValue,
    canApprove,
    isAdmin,
    updateCallback,
    updateEditedCostoCallback,
    cancelEditingCallback,
}) => <TableRowDetail contentComponent={({ row }) => {
    const isDisabled = {
        cancel: (!canApprove && row.status != statusRichiestaServizio.inAttesa) || (canApprove && (row.status == statusRichiestaServizio.annullato)),
        approve: row.status == statusRichiestaServizio.approvato
    };
    
    const hasMedico = !!row.medicoName;
    const hasStimaCosto = !!row.stimaCosto;
    const onEditSubmit = () => updateCallback(row.idRichiesta, undefined, costoEditedValue(row.idRichiesta)).then(() => cancelEditingCallback(row.idRichiesta));
    return (<>
        <div className="card">
            <div className="p-8">
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Tipo di richiesta
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        <h5>{row.type}</h5>
                    </span>
                </div>
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Descrizione
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.description.split('\n').reduce((arr, line) => [...arr, line, <br />], []).slice(0, -1)}
                    </span>
                </div>
                
                {hasMedico ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Medico
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.medicoFullname}
                    </span>
                </div> : null}
                {hasMedico ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Specializzazione
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.medicoSpecializzazione}
                    </span>
                </div> : null}
                {hasMedico ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Mail
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.medicoMail}
                    </span>
                </div> : null}
                {hasMedico ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Telefono
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.medicoPhone}
                    </span>
                </div> : null}
                {hasMedico ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Indirizzo
                    </span>
                    <span className="font-weight-bolder text-dark-50 text-xl-right">
                        {row.medicoAddress}
                    </span>
                </div> : null}
                {hasStimaCosto ? <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Stima costo dall'agente
                    </span>
                    <div className="d-flex align-items-center">
                        <span className="font-weight-bolder text-dark-50 text-xl-right">{row.stimaCosto}€</span>
                    </div>
                </div> : null}
                <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Costo
                    </span>
                    <div className="d-flex align-items-center">
                        {canApprove && costoIsEditing(row.idRichiesta)
                            ? <><Form onSubmit={onEditSubmit}>
                                <Form.Control
                                    type="text" 
                                    name="costo"
                                    placeholder="€"
                                    value={costoEditedValue(row.idRichiesta)}
                                    onInput={(e) => {
                                        // checks if a string is a positive number or empty
                                        let val = e.target.value.replace(',', '.').replace(/[^0-9.]/g, '');
                                        const shouldWait = val.endsWith('.');
                                        if (shouldWait && val.search(/\.(.*)(\.)/) >= 0) {
                                            val = val.substr(0, val.length - 1);
                                        }
                                        const parsed = shouldWait ? NaN : parseFloat(val);
                                        if (!isNaN(parsed) && parsed >= 0) {
                                            updateEditedCostoCallback(
                                                row.idRichiesta, 
                                                Math.round(parsed * 100) / 100
                                            );
                                        } else {
                                            updateEditedCostoCallback(row.idRichiesta, val);
                                        }
                                    }}
                                    autoFocus
                                />
                            </Form>€</>
                            : <>
                                <span className="font-weight-bolder text-dark-50 text-xl-right">{(row.costo === undefined) ? '-' : row.costo}€</span>
                                {canApprove ? <Button
                                    variant="light-success"
                                    className="btn-xs btn-icon ml-2"
                                    disabled={isRowLoading(row.idRichiesta)}
                                    onClick={() => updateEditedCostoCallback(row.idRichiesta, row.costo)}
                                >
                                    <span className={`svg-icon svg-icon-xs`}>
                                        {" "}
                                        <SVG src={toAbsoluteUrl(icons.edit)} />
                                    </span>
                                </Button>: null}
                            </>}
                        {canApprove && costoIsEditing(row.idRichiesta)
                            ? <Button
                                variant="success"
                                className="btn-xs btn-icon ml-2"
                                disabled={isRowLoading(row.idRichiesta)}
                                onClick={onEditSubmit}
                            >
                                <i className="ki ki-check icon-xs"></i>
                            </Button>
                            : null}
                        {canApprove && costoIsEditing(row.idRichiesta)
                            ? <Button
                                variant="light-danger"
                                className="btn-xs btn-icon ml-2"
                                disabled={isRowLoading(row.idRichiesta)}
                                onClick={() => cancelEditingCallback(row.idRichiesta)}
                            >
                                <i className="ki ki-close icon-xs"></i>
                            </Button>
                            : null}
                    </div>
                </div>
            </div>
            <div className="separator separator-solid"></div>
                { isAdmin
                ? <>
                <div>
                    <div className="float-xl-right">
                        {makeActionBtn({
                            name: canApprove ? "Rifiuta" : "Annulla",
                            callback: (id) => updateCallback(id, statusRichiestaServizio.annullato, undefined),
                            disabled: isRowLoading(row.idRichiesta) || isDisabled.cancel,
                            variant: "danger",
                            icon: icons.close,
                            value: row.idRichiesta,
                        })}
                        {canApprove
                            ? makeActionBtn({
                                name: "Approva",
                                callback: (id) => updateCallback(id, statusRichiestaServizio.approvato, undefined),
                                disabled: isRowLoading(row.idRichiesta) || isDisabled.approve,
                                variant: "primary",
                                icon: faSquareCheck,
                                value: row.idRichiesta,
                            })
                            : null}
                    </div>
                </div>
                </>
                : null}
            </div>
        </>);
    }} />;


// Fields: idRichiesta, costo, description, status, dateCreated, dateUpdated, agenteName, agenteLastname, agenteFullname, medicoName, medicoLastname, medicoFullname, medicoMail, medicoPhone
// Columns definition
const serviziColumns = [
    { name: "agenteFullname", title: "Agente" },
    { name: "status", title: "Stato" }, // 0:In attesa, 1: Approvato, 2:Annullato/Rifiutato
    { name: "type", title: "Tipo" },
    { name: "dateUpdated", title: "Ultima Modifica" },
    { name: "medicoFullname", title: "Medico" },
    { name: "costo", title: "Stima costo" },
    { name: "dateCreated", title: "Data Creazione" },
];
const serviziColumnExtensions = [
    { columnName: "agenteFullname", wordWrapEnabled: true },
    { columnName: "status", width: "auto" },
    { columnName: "type", wordWrapEnabled: true },
    { columnName: "dateUpdated", wordWrapEnabled: true },
    { columnName: "medicoFullname", wordWrapEnabled: true },
    { columnName: "costo", wordWrapEnabled: true },
    { columnName: "dateCreated", wordWrapEnabled: true },
];

// Messages
const tableMessages = {
    noData: "Nessuna richiesta effettuata",
};
const filterRowMessages = {
    filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
    showAll: "Tutte",
    rowsPerPage: "Righe per pagina",
    info: "Da {from} a {to} ({count} richieste)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "lastUpdate", direction: "desc" }];

export const RichiesteServizi = () => {
    // table data
    const [rows, setRows] = useState([]);
    console.log('Numero totale di righe:', rows);
    const [loading, setLoading] = useState(false);
    const [loadingRows, setLoadingRows] = useState({});
    const [costoEditingRows, setCostoEditingRows] = useState({});
    const [_internalUpdateCount, _setInternalUpdateCount] = useState(0);

    // User redux
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const role = parseInt(user.idRole);

    // nascondi colonna "agente" se non admin/magazziniere
    const columns = (role === roles.admin || role === roles.developer || role ===  roles.magazziniere || role === roles.contabile)
        ? serviziColumns 
        : serviziColumns.slice(1);

        const updateFromServer = () => {
            setLoading(true);
            apiServizi
                .getAll(user.authToken)
                .then((data) => {
                    const sortedData = data.sort((a, b) => {
                        return new Date(b.dateCreated) - new Date(a.dateCreated);
                    });
                    setRows(sortedData);
                })
                .then(() => setLoading(false));
        };
        
    useEffect(() => {
        updateFromServer();
        /*eslint-disable-next-line*/
    }, [_internalUpdateCount]);

    const doUpdate = () => {
        _setInternalUpdateCount(_internalUpdateCount + 1);
    };

    const addLoadingRow = (id) => {
        const clone = { ...loadingRows };
        clone[id] = true;
        setLoadingRows(clone);
    };
    const removeLoadingRow = (id) => {
        const clone = { ...loadingRows };
        delete clone[id];
        setLoadingRows(clone);
    };

    const addCostoEditingRow = (id, costo) => {
        const clone = { ...costoEditingRows };
        clone[id] = costo
        setCostoEditingRows(clone);
    };
    const removeCostoEditingRow = (id) => {
        const clone = { ...costoEditingRows };
        delete clone[id];
        setCostoEditingRows(clone);
    };

    // paging and sorting
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [sorting, setSorting] = useState(initialSorting);
    const [expandedRows, setExpandedRows] = useState([]);

    /*eslint-disable-next-line*/
    //const campioniMap = new Map(rows.map((c) => [c.id, c]));
    return (
        <>
            <Card className="card-custom gutter-b">
                <Card.Header className="border-0 py-5 row">
                    <Card.Title className="float-left col-auto">
                        <div>
                            <h3 className="font-weight-bolder text-dark">
                                Richieste di Servizi
                            </h3>
                            <h6 className="font-weight-light text-dark mobile-only">
                                Scorri a destra la tabella per altre informazioni
                            </h6>
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                    {!loading ? (
                        <Grid rows={[...rows.values()].map(row => ({ ...row }))} columns={columns}>
                            <DateFormattedColumn for={["dateCreated", "dateUpdated"]} />
                            <StatoFormattedColumn for={["status"]} />
                            <CostoWithFallbackFormattedColumn for={["costo"]} />
                            {/* <ExtraFormattedColumn for={["extra"]} /> */}
                            <RowDetailState 
                                expandedRowIds={expandedRows} 
                                onExpandedRowIdsChange={setExpandedRows} />
                            <FilteringState />
                            <IntegratedFiltering />
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={setCurrentPage}
                                pageSize={pageSize}
                                onPageSizeChange={setPageSize}
                            />
                            <IntegratedPaging />
                            <SortingState sorting={sorting} onSortingChange={setSorting} />
                            <IntegratedSorting />
                            <Table
                                messages={tableMessages}
                                tableComponent={TableComponent}
                                columnExtensions={serviziColumnExtensions}
                                rowComponent={getTableRow(
                                    (id) => setExpandedRows(expandedRows.includes(id) 
                                        ? [...expandedRows].filter((v) => v != id) 
                                        : [...expandedRows, id])
                                )}
                            />
                            <TableHeaderRow showSortingControls />
                            <TableFilterRow
                                messages={filterRowMessages}
                                cellComponent={FilterCell}
                            />
                            <RichiestaRowDetail
                                /*eslint-disable-next-line*/
                                isRowLoading={(id) => id in loadingRows}
                                canApprove={role === roles.admin || role === roles.developer || role === roles.contabile}
                                isAdmin= {role === roles.admin}
                                costoIsEditing={(id) => id in costoEditingRows}
                                costoEditedValue={(id) => costoEditingRows[id]}                                
                                updateCallback={(id, nuovoStatus, nuovoCosto) => {
                                    addLoadingRow(id);
                                    return apiServizi.update(user.authToken, id, nuovoStatus, nuovoCosto).then(() => {
                                        removeLoadingRow(id);
                                        doUpdate();
                                    });
                                }}
                                updateEditedCostoCallback={addCostoEditingRow}
                                cancelEditingCallback={removeCostoEditingRow}
                            />
                            <PagingPanel
                                messages={pagingPanelMessages}
                                pageSizes={pageSizes}
                            />
                        </Grid>
                    ) : (
                        <div>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="rect" height={518} />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
